@tailwind base;
@tailwind components;
@tailwind utilities;

.glow-on-hover {
    transition: box-shadow 0.2s ease-in-out;
}

.glow-on-hover:hover {
    box-shadow: 0 0 14px 6px rgba(255, 32, 32, 0.45); /* Adjust color and size as needed */
}